<template>
  <the-wrapper :isAside="isAside" :isSpinner="isSpinner">
    <slot>
      <h2>Ваши линии</h2>
      <br />
      <current-lines :userLines="userLines.ServiceItems"></current-lines>
      <br />
      <base-back-button></base-back-button>
    </slot>
    <template #aside>
      <the-battery style="padding-top: 4rem"></the-battery>
    </template>
  </the-wrapper>
</template>

<script>
import CurrentLines from '@/components/Vpbx/virtual_pbx/CurrentLines.vue';
import BaseBackButton from '@/components/shared/buttons/BaseBackButton.vue';
import TheBattery from '@/components/TheBattery.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { getLines } from '@/api/vpbx';
import { mapMutations } from 'vuex';
import { catchFunction } from '@/additional_helpers/helpers';

export default {
  data() {
    return {
      isLoading: true,
      userLines: {},
      isAside: true,
      isSpinner: true
    };
  },

  components: {
    CurrentLines,
    BaseBackButton,
    TheBattery,
    TheWrapper
  },

  created() {
    getLines()
      .then((resultLines) => {
        this.userLines = resultLines;
      })
      .then(() => this.SHOW_LOADING(false))
      .catch((e) => catchFunction(e));
  },

  methods: mapMutations({ SHOW_LOADING: 'SHOW_LOADING' })
};
</script>

<style></style>
